



























































import AstCheckbox from '@components/Checkbox.vue'
import EmailSelect from '@components/EmailSelect.vue'
import scheduleData from '@services/scheduleData'

interface IReqScheduleField {
	name: string
	prop: string
	required: boolean
}

let requiredFields: IReqScheduleField[] = [
	{ name: 'Owner Name', prop: 'isOwnerNameRequired', required: false },
	{ name: 'Patient Birthday', prop: 'isPatientBirthdateRequired', required: false },
	{ name: 'Patient Gender', prop: 'isPatientGenderRequired', required: false },
	{ name: 'Patient Species', prop: 'isPatientSpeciesRequired', required: false },
	{ name: 'Patient Breed', prop: 'isPatientBreedRequired', required: false },
	{ name: 'Patient Location', prop: 'isPatientLocationRequired', required: false },
	{ name: 'Patient Weight', prop: 'isPatientWeightRequired', required: false },
	{ name: 'Procedure Id', prop: 'isProcedureIdRequired', required: false },
	{ name: 'Procedure Description', prop: 'isProcedureDescriptionRequired', required: false },
	{
		name: 'Procedure Step Description',
		prop: 'isProcedureStepDescriptionRequired',
		required: false,
	},
	{ name: 'Procedure Priority', prop: 'isProcedurePriorityRequired', required: false },
	{ name: 'Procedure Pre-Medication', prop: 'isProcedurePreMedicationRequired', required: false },
	{ name: 'Special Needs', prop: 'isSpecialNeedsRequired', required: false },
	{ name: 'Medical Alerts', prop: 'isPatientMedicalAlertsRequired', required: false },
	{ name: 'Referring Physican Name', prop: 'isReferringPhysicianRequired', required: false },
	{ name: 'Referring Physican Phone', prop: 'isReferringPhysicianPhoneRequired', required: false },
	{ name: 'Requesting Physican Name', prop: 'isRequestingPhysicianRequired', required: false },
	{ name: 'Comments', prop: 'isCommentsRequired', required: false },
	{ name: 'Admitting Diagnosis', prop: 'isAdmittingDiagnosisRequired', required: false },
]

export default {
	name: 'ScheduleAdministration',
	components: {
		AstCheckbox,
		EmailSelect,
	},
	props: {},
	data() {
		return {
			config: {
				modalities: [],
				consultants: [],
			},
			requiredFields,
		}
	},
	computed: {
		localConsultants() {
			return this.config.consultants.filter(c => c.local)
		},
	},
	async created() {
		this.refresh()
	},
	beforeDestroy() {
		this.saveChanges()
	},
	methods: {
		async refresh() {
			this.config = await scheduleData.getConfiguration()
			requiredFields.forEach(f => (f.required = !!this.config[f.prop]))
		},
		async saveChanges() {
			let config: IScheduleConfiguration = this.config
			requiredFields.forEach(f => (this.config[f.prop] = f.required))
			scheduleData.saveConfiguration(config)
		},
	},
}
