





































































import { mapState, mapActions } from 'vuex'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import DataTable from '@components/DataTable.vue'
import AstInput from '@components/Input.vue'
import AstCheckbox from '@components/Checkbox.vue'
import { userData } from '@services/userData'
import { openUserProfileDlg } from '@dialogs/UserProfile.vue'
import MetaColumn from '@store/models/base/TableMetaColumn'

export default {
	name: 'UserAdmin',
	components: {
		AstInput,
		DataTable,
		AstCheckbox,
	},
	props: {
		selectConsultantId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			users: [],
			search: null,
			isAdmin: false,
			isLoading: true,
			isSaving: false,
			showDisabled: false,
			sort: { by: 'username', isAscending: true },
			columns: [
				MetaColumn({ name: 'Username', sortName: 'username' }),
				MetaColumn({ name: 'Full Name', sortName: 'fullName' }),
				{}, // Icons,
			],
		}
	},
	computed: {
		userList() {
			let search = this.search && this.search.toLowerCase()
			return this.users
				.filter(u => {
					if (!u.isEnabled && !this.showDisabled) return false
					if (search) {
						return (
							u.username.toLowerCase().includes(search) || u.fullName.toLowerCase().includes(search)
						)
					}
					return true
				})
				.sort((a, b) => {
					const sa = a[this.sort.by]
					const sb = b[this.sort.by]
					if (!this.sort.by || !sa || !sb) return 0
					return sa.localeCompare(sb) * (this.sort.isAscending ? 1 : -1)
				})
		},
		listSort() {
			return {
				orderBy: this.sort.by,
				isAscending: this.sort.isAscending,
				isSorted: true,
			}
		},
	},
	created() {
		this.loadUsers()
	},
	methods: {
		async loadUsers() {
			this.isLoading = true
			try {
				this.users = await userData.getAllUsers()
			} finally {
				this.isLoading = false
			}
		},
		async newUser() {
			await openUserProfileDlg(null, true)
			this.loadUsers()
		},
		async setUserDefaults() {
			await openUserProfileDlg(null, false, true)
			this.loadUsers()
		},
		async editUser(user: IBasicUser) {
			await openUserProfileDlg(user.id)
			this.loadUsers()
		},
		async deleteUser(user: IBasicUser) {
			let answer = await showConfirm(`Are you sure you want to delete ${user.fullName}?`)
			if (answer) {
				await userData.deleteUser(user.id)
				this.users.splice(this.users.indexOf(user), 1)
			}
		},
		enableUser(user: IBasicUser) {
			userData.enableUser(true, user.id).then(() => (user.isEnabled = true))
		},
		disableUser(user: IBasicUser) {
			userData.enableUser(false, user.id).then(() => (user.isEnabled = false))
		},
		handleSortSelected(evt) {
			this.sort.by = evt.name
			this.sort.isAscending = evt.isAscending
		},
	},
}
