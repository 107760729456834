<template>
	<div class="autocomplete-settings">
		<form ref="autoCompleteForm" @submit.prevent="saveEntry">
			<div class="autocomplete-key">
				<ast-input ref="keyInput" v-model="autoComplete.key" label="Key" maxlength="100" />
			</div>
      <div class="autocomplete-replacement">
        <ast-input v-model="autoComplete.replacement" type="textarea" label="Replacement" maxlength="2000" />
      </div>
			<button
				class="btn btn-primary save-button"
				:disabled="isEntrySaving || !autoComplete.key || !autoComplete.replacement"
				@click.native="saveEntry"
			>
				<svg-icon icon="check" fixed />
				Save
			</button>
		</form>
		<div v-if="!list.length" class="list-empty">
			No Auto-Complete entries found
		</div>
		<ast-data-table
			v-show="list.length"
			:is-hoverable="false"
			:is-loading="isLoading"
			style="background: var(--secondary-bg); border: 1px solid var(--secondary-border); overflow-y: auto;"
		>
			<thead>
				<tr>
					<th></th>
					<th>Key</th>
					<th>Replacement</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(entry, i) in list" :key="i">
					<td style="white-space: nowrap;">
						<button class="edit-button" title="Edit" @click="editEntry(entry)">
							<svg-icon icon="pencil" />
						</button>
						<button class="delete-button" title="Delete" @click="deleteEntry(entry)">
							<svg-icon icon="close" />
						</button>
					</td>
					<td class="key">
						{{ entry.key }}
					</td>
          <td style="white-space: pre-wrap;">{{ removeHtmlTags(entry.replacement) }}</td>
				</tr>
			</tbody>
		</ast-data-table>
	</div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {showConfirm} from '@dialogs/ConfirmDlg.vue'
import AstDataTable from '@components/DataTable'
import AstInput from '@components/Input'

export default {
	name: 'AutoCompleteSettingView',
	components: {
		AstInput,
		AstDataTable,
	},
	data() {
		return {
			autoComplete: {
				key: '',
				replacement: '',
			},
			isEntrySaving: false,
		}
	},
	computed: {
		...mapState({
			list: state => state.settings.autoComplete.list,
			isLoading: state => state.settings.autoComplete.isListLoading,
		}),
	},
	methods: {
		...mapActions(['deleteAutoComplete', 'saveAutoComplete']),
		async deleteEntry(entry) {
			if (!(await showConfirm('Are you sure you want to delete this entry?'))) return
			this.deleteAutoComplete(entry)
		},
		editEntry(entry) {
			this.autoComplete = { ...entry }
      if (this.containsHtml(entry.replacement)) {
        this.autoComplete.replacement = this.removeHtmlTags(entry.replacement)
      }
			this.$refs.autoCompleteForm.scrollIntoView()
		},
		async saveEntry() {
      if (!this.autoComplete.key.trim() || !this.autoComplete.replacement.trim()) return
      try {
        this.isEntrySaving = true
        const existingEntry = this.list.find(
          a => a.key.trim().toLowerCase() === this.autoComplete.key.trim().toLowerCase()
        )
        await this.saveAutoComplete({
          id: existingEntry ? existingEntry.id : null,
          key: this.autoComplete.key,
          replacement: this.autoComplete.replacement,
        })
      } finally {
        this.isEntrySaving = false
        this.resetModel()
        this.$refs.keyInput.$refs.input.focus()
      }
		},
		resetModel() {
			this.autoComplete.key = ''
			this.autoComplete.replacement = ''
		},
    containsHtml(text) {
      return /<\/?[a-z][\s\S]*>/i.test(text)
    },
    removeHtmlTags(text) {
      return text.replaceAll(/<li>/ig, '- ')
        .replaceAll(/(<([^>]+)>)/ig,'')
        .replaceAll(/&nbsp;/ig, ' ')
        .replaceAll(/&amp;/ig, '&')
        .replaceAll(/&lt;/ig, '<')
        .replaceAll(/&gt;/ig, '>')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

form {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	margin: -4px -4px 16px -4px;
}
th {
	font-size: 0.9em;
}
td.key {
	word-break: normal;
}
td {
	width: 0.1%; // shrink first two columns
	&:last-child {
		width: auto;
	}
}

.autocomplete-key,
.autocomplete-replacement,
.save-button {
	margin: 8px 4px;
}
.autocomplete-key,
.autocomplete-replacement {
	max-width: 450px;
}
.save-button {
	align-self: flex-start;
}
.list-empty {
	text-align: center;
	margin: 25px 0;
}
.save-button {
	height: 36px;
}
.delete-button,
.edit-button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	background: var(--button-default-bg);
	color: var(--button-default-color);
	outline: none;
	border: 0;
	width: 24px;
	height: 24px;
	cursor: pointer;
	&:hover {
		background: var(--button-default-bg-hover);
	}
}
.delete-button {
	margin-left: 4px;
	&:hover {
		background: var(--button-danger-bg);
		color: var(--button-danger-color);
	}
}
@media (max-width: $mqMedium) {
	.autocomplete-key,
	.autocomplete-replacement,
	.save-button {
		flex-basis: 100%;
	}
}
</style>
